import styles from '../styles/ArticlePage.module.scss';
import React from 'react';
import { client } from '../lib/api/apolloClient';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { ARTICLE_PAGE_QUERY } from '../lib/api/queries';
import NotFoundPage from './404';
import { ArticleJumbo, useWindowSizeBreakpoint } from '../lib/components';
import ArrowDownIcon from '../public/icons/ico-arrow-down.svg';
import { SectionPicker } from '../lib/components/sections/sectionPicker';
import { MyHead } from '../lib/components/myHead';
import { getAllFaqs } from '../lib/utils/faqHelper';

export default function ArticlePage({ page, fallbackSeo }: any) {
  const brWidth = useWindowSizeBreakpoint();

  if (!page || page.title == null || page.title == '') {
    return <NotFoundPage />;
  }

  const handleScrollDown = () => {
    document.getElementById('article-content').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const myPage = {
    node: {
      level: page.ancestors.edges.length,
      name: page.name,
      __typename: page.__typename,
      slug: page.slug,
    },
    __typename: 'ContentEdge',
  };

  const getDownIcon = () => {
    if (brWidth > 1639 || (!page.desktopMedia && !page.mobileMedia)) {
      return (
        <div className={styles.container}>
          <div onClick={handleScrollDown} className={styles.btn}>
            <ArrowDownIcon />
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <MyHead
        title={page.seoTitle ?? fallbackSeo.seoTitle}
        description={page.seoDescription ?? fallbackSeo.seoDescription}
        ogTitle={page.ogTitle ?? page.seoTitle ?? fallbackSeo.ogTitle}
        ogDescription={
          page.ogDescription ?? page.seoDescription ?? fallbackSeo.ogDescription
        }
        ogImage={page.ogImage?.url ?? fallbackSeo.ogImage?.url}
        hidePage={page.hidePage}
        excludeLinks={page.excludeLinks}
        faqs={getAllFaqs(page.sections)}
      />
      <div>
        <ArticleJumbo
          title={page.title}
          description={page.introText}
          links={page.link && page.link}
          hideBreadcrumbs={page.campaignPage}
          breadcrumbs={
            page.ancestors.edges ? [...page.ancestors.edges, myPage] : []
          }
          image={{
            desktopMedia: page.desktopMedia,
            desktopVideo: page.desktopVideo,
            mobileMedia: page.mobileMedia,
            mobileVideo: page.mobileVideo,
          }}
          scrollDownTapped={handleScrollDown}
          hideScrollDownBtn={false}
        />
      </div>
      {getDownIcon()}
      <div id='article-content'>
        <SectionPicker sections={page.sections} />
      </div>
    </div>
  );
}

export const getServerSideProps: GetServerSideProps = async ({
  req,
}: GetServerSidePropsContext) => {
  const { url } = req;

  let slug = '';
  if (url.startsWith('/_next')) {
    slug = url.split('slug=').pop().split('?')[0];
  } else {
    slug = url.split('/').pop().split('?')[0];
  }
  try {
    const response = await client.query({
      query: ARTICLE_PAGE_QUERY,
      variables: {
        slug: slug,
      },
    });

    const page = response.data?.allArticlePage.edges[0]?.node;
    const fallbackSeo = response.data?.fallbackSeo;

    return { props: { page: page ?? {}, fallbackSeo: fallbackSeo ?? {} } };
  } catch (e) {}

  return { props: {} };
};
