import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { ArticleJumbo } from "../lib/components";
import { useUI } from "../lib/context/ui";

const NotFoundPage: NextPage = () => {
  const router = useRouter();
  const { base } = useUI();

  useEffect(() => {
    if (!base) return;
    checkRedirects();
  }, [base]);

  const checkRedirects = async () => {
    if (base.redirects) {
      let asPath = router.asPath;
      let query = "";
      if (asPath.includes("?")) {
        asPath = asPath.split("?")[0];
        query = "?" + router.asPath.split("?")[1];
      }

      const redirect = base.redirects.split("\n").find((val: string) => {
        return asPath.includes(val.split(":")[0]);
      });

      const newUrl = redirect?.split(":")[1];

      if (newUrl) {
        await router.push(newUrl + query);
        return;
      }
    }
  };

  if (!base) return null;

  return (
    <div>
      <ArticleJumbo
        title={base?.errorPageTitle}
        description={base?.errorPageIntroText}
        links={base?.errorPageLinks && base.errorPageLinks}
        hideBreadcrumbs={true}
        breadcrumbs={[]}
        image={{
          desktopMedia: base?.errorPageMedia,
          mobileMedia: base?.errorPageMedia,
        }}
        hideScrollDownBtn={true}
        scrollDownTapped={() => {}}
      />
    </div>
  );
};

export default NotFoundPage;
